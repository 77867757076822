import axios from "axios";
import { EncryptService } from "../services/encryptDecrypt";

const API_URL = 'https://cart.numbermall.in/Services/Webservices/'; //process.env.PUBLIC_URL;

// Master Data
export const getmasterdata = function () {
  return axios
    .post(API_URL + "getmasterdata ", { "orgid": "0", "hflag": "S" }, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};



// Register
export const userRegister = function (data) {
  return axios
    .post(API_URL + "userRegistration", data, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

//Login
export const Login = function (data) {
  return axios
    .post(API_URL + "login", data, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return (error);
    });
};



//OTP
export const checkOTP = function (data) {
  return axios
    .post(API_URL + "checkOTP", data, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
      return (error)
    });
};

//getCategories
export const getCategories = function (data) {
  return axios
    .post(API_URL + "getCategories", data, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data.response);
    })
    .catch(function (error) {
      console.log(error);
    });
};
//getTopCategories
export const getTopCategories = function (data) {
    return axios
        .post(API_URL + "getTopCategories", data, {
            headers: {
                Ldatetime: EncryptService("2020-01-01 00:00:00"),
                Tokenid: EncryptService("9999999999numbermall"),
                Sessionid: EncryptService("8888888888----<-1000"),
            },
        })
        .then(function (response) {
            return (response.data.response);
        })
        .catch(function (error) {
            console.log(error);
        });
};
//getTrendingItems
export const getTrendingItems = function (data) {
    return axios
        .post(API_URL + "getTrendingItems", data, {
            headers: {
                Ldatetime: EncryptService("2020-01-01 00:00:00"),
                Tokenid: EncryptService("9999999999numbermall"),
                Sessionid: EncryptService("8888888888----<-1000"),
            },
        })
        .then(function (response) {
            return (response.data.response);
        })
        .catch(function (error) {
            console.log(error);
        });
};
// API to get products
export const getProductsById = function () {
  return axios
    .post(API_URL + "getCategoryItemsByIdsUpdated", {
      "orgid": 0,
      "cstatus": 0,
      "hflag": "S"
    }, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data.code === 0 ? response.data.response : []);
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Get faqs
export const getFaqs = function () {
  return axios
    .post(API_URL + "getfaqs", { "hflag": "S" }, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};
// Get Testimonials
export const getTestimonials = function () {
  return axios
    .post(API_URL + "getTestimonials", { "tstatus": "0", "hflag": "S" }, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data.response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Get Addresses
export const getUserAddress = function (data) {
  return axios
    .post(API_URL + "getUserAddress", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
      return (error)
    });
};

// Add Addresses
export const addUserAddress = function (data) {
  return axios
    .post(API_URL + "addUserAddress", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

//save payment process
export const savePayment = function (data) {
  return axios
    .post("https://cart.numbermall.in/Services/Payservices/SaveLivePaymentprocess", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};


// Thank You
export const updatePaymentSuccess = function (data) {
  return axios
    .post(API_URL + "updatePaymentStatus", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateReturnStatus = function (data) {
  return axios
    .post(API_URL + "updateReturnStatus", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};


export const UpdateorderPaystatus = function (data) {
  return axios
    .post("https://cart.numbermall.in/Services/Payservices/UpdateorderPaystatus", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateOrderStatus = function (data) {
  return axios
    .post(API_URL + "updateOrderStatus", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateCancelStatus = function (data) {
  return axios
    .post(API_URL + "cancelOrder", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Wallet
export const addMoneyToWallet = function (data) {
  return axios
      .post(API_URL + "addWallet", data, {
        headers: {
          Ldatetime: localStorage.getItem("Ldatetime"),
          Tokenid: localStorage.getItem("Tokenid"),
          Sessionid: localStorage.getItem("Sessionid"),
        },
      })
      .then(function (response) {
        return (response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
};
export const getWalletBalance = function (data) {
  return axios
      .post(API_URL + "getWalletBalance", data, {
        headers: {
          Ldatetime: localStorage.getItem("Ldatetime"),
          Tokenid: localStorage.getItem("Tokenid"),
          Sessionid: localStorage.getItem("Sessionid"),
        },
      })
      .then(function (response) {
        return (response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
};
export const getWalletTransactions = function (data) {
  return axios
      .post(API_URL + "getTransactionsHistory", data, {
        headers: {
          Ldatetime: localStorage.getItem("Ldatetime"),
          Tokenid: localStorage.getItem("Tokenid"),
          Sessionid: localStorage.getItem("Sessionid"),
        },
      })
      .then(function (response) {
        return (response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
};
export const getTransactionStatus = function (data) {
    return axios
        .post(API_URL + "checkTransactionStatus", data, {
            headers: {
                Ldatetime: localStorage.getItem("Ldatetime"),
                Tokenid: localStorage.getItem("Tokenid"),
                Sessionid: localStorage.getItem("Sessionid"),
            },
        })
        .then(function (response) {
            return (response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
};
// Orders
export const addOrder = function (data) {
  return axios
    .post(API_URL + "addOrderMasterLatest", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Orders
export const getOrders = function (data) {
  return axios
    .post(API_URL + "getUserOrders", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Get Order details
export const getOrderDetails = function (data) {
  return axios
    .post(API_URL + "getOrderDetailsUpdated", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Forgot Password
export const forgotPassword = function (data) {
  return axios
    .post(API_URL + "forgotPassword", data, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
      return (error);
    });
};

// Change Password
export const changePassword = function (data) {
  return axios
    .post(API_URL + "changepassword", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
      return (error);
    });
};

// set Password
export const setPassword = function (data) {
  return axios
    .post(API_URL + "setPassword", data, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
      return (error)
    });
};

// Delete Account
export const deleteAccount = function (data) {
    return axios
        .post(API_URL + "deleteAccount", data, {
            headers: {
                Ldatetime: localStorage.getItem("Ldatetime"),
                Tokenid: localStorage.getItem("Tokenid"),
                Sessionid: localStorage.getItem("Sessionid"),
            },
        })
        .then(function (response) {
            return (response.data);
        })
        .catch(function (error) {
            console.log(error);
            return (error);
        });
};


// get org items
export const addContactMaster = function (data) {
  return axios
    .post(API_URL + "addContactMaster", data, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
      return (error);
    });
};

// get all subcategories
export const getAllSubCategories = function (data) {
  return axios
    .post(API_URL + "getAllSubCategories", { "orgid": "0", "hflag": "S" }, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data.response);
    })
    .catch(function (error) {
      console.log(error);
      return (error);
    });
};

// get team members
export const getTeamMembers = function (data) {
  return axios
    .post(API_URL + "getTeamMembers", { "tstatus": "0", "hflag": "S" }, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data.response);
    })
    .catch(function (error) {
      console.log(error);
      return (error);
    });
};

// user update
export const userUpdate = function (data) {
  return axios
    .post(API_URL + "userUpdate", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

// deliver types
export const getDeliveryCharges = function (data) {
  return axios
    .post(API_URL + "getDeliveryOptCharges", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};


// get banners
export const getBanners = function () {
  return axios
    .post(API_URL + "getBanners", {
      "hflag": "S"
    }, {
      headers: {
        Ldatetime: EncryptService("2020-01-01 00:00:00"),
        Tokenid: EncryptService("9999999999numbermall"),
        Sessionid: EncryptService("8888888888----<-1000"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getsearchUsers = function (data) {
  return axios
    .post("https://cart.numbermall.in/Services/Webservices/getSearchCustomers", data, {
      headers: {
        Ldatetime: localStorage.getItem("Ldatetime"),
        Tokenid: localStorage.getItem("Tokenid"),
        Sessionid: localStorage.getItem("Sessionid"),
      },
    })
    .then(function (response) {
      return (response.data);
    })
    .catch(function (error) {
      console.log(error);
      return (error);
    });
};
